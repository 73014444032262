@use '../abstracts/variables' as var;
@use '../base/mixins';
html,
body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
}

body {
  display: flex;
  flex-direction: column;
  background-color: #ade8ff;
  &.no-scroll {
    overflow: hidden;
  }
}

.center-x {
  text-align: center;
}

// Flex layout
.flex-column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: stretch;

  > :not(:first-child) {
    margin-left: var.$gap-md;
  }

  &.center-x {
    align-items: center;
  }

  &.center-y {
    justify-content: center;
  }
}

.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: stretch;

  > :not(:first-child) {
    margin-left: var.$gap-md;
  }

  &.center-x {
    justify-content: center;
  }

  &.center-y {
    align-items: center;
  }
}

.fit-max {
  > :first-child {
    flex-basis: content;
  }

  > :not(:first-child) {
    flex-grow: 1;
  }
}

.container {
  position: relative;
}

.circle-background {
  position: absolute;
  z-index: -1;
  min-height: 100%;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  &--index {
    left: 37%;
    @include mixins.respond-to(md) {
      left: 0;
      right: 0;
    }
  }
  &--product {
    left: 60px;
    @include mixins.respond-to(md) {
      left: 0;
    }
  }
  &::after {
    content: '';
    position: absolute;
    top: -120px;
    right: 0;
    left: 0;
    background-image: url('src/assets/images/icons/three-circles.svg');
    z-index: 0;
    background-repeat: no-repeat;
    width: 100%;
    min-height: 1100px;
    background-size: 1100px;
    pointer-events: none;
    background-position: center;
  }
  @include mixins.respond-to(md) {
    &::after {
      background-size: 450px;
      background-size: 100%;
      inset: 0;
      width: 100%;
      background-position: top;
      top: 50px;
    }
  }
}
