@use '../base/fonts/inter.scss' as fontInter;
@use '../base/mixins';
.blog {
  margin-bottom: 70px 20px;
  &__inner {
    padding: 50px 0;
    @include mixins.respond-to(md) {
      padding: 30px 0 30px 0;
    }
    @include mixins.respond-to(sm) {
      padding: 30px 0 30px 16px;
    }
  }
  &--products {
    .blog-item {
      margin: 20px 0;
      @include mixins.respond-to(md) {
        margin: 0 0 20px 0;
      }
    }
  }
}
.blog-item {
  border-radius: 20px;
  background: #fff;
  position: relative;
  display: block;
  margin: 20px 10px;
  @include mixins.respond-to(md) {
    margin: 20px 0;
  }
  &__inner {
    padding: 40px 50px 70px 50px;
    max-height: 305px;

    @include mixins.respond-to(lg) {
      padding: 20px 30px;
      max-height: 600px;
    }

    &--detail {
      max-height: 350px;
      padding: 40px 40px 70px 40px;
    }
  }
  &__image {
    height: 200px;
    position: relative;
    img {
      object-fit: cover;
      display: block;
      width: 100%;
      height: 100%;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
    }
    &--detail {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        object-fit: unset;
        width: 75px;
        height: auto;
        border-radius: 0;
      }
    }
  }
  &__labels {
    position: absolute;
    right: -10px;
    bottom: -14px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .label {
      display: inline-block;
      margin: 4px 0;
      padding: 6px 8px 3px 8px;
      text-align: center;
      border-radius: 6px;
      background: #4bc6b6;
      color: #fff;
      font-feature-settings:
        'clig' off,
        'liga' off;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
  }
  &__title {
    color: #070058;
    font-feature-settings:
      'clig' off,
      'liga' off;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    margin-bottom: 50px;
    position: relative;
    display: block;
    @include mixins.animation();
    &::after {
      content: '';
      position: absolute;
      bottom: -25px;
      left: 0;
      height: 1px;
      width: 100%;
      background-color: #08005c;
      opacity: 0.1278;
    }
    &:hover {
      text-decoration: underline;
    }
    &--detail {
    }
  }
  &__text {
    color: rgba(7, 0, 88, 0.8);
    font-feature-settings:
      'clig' off,
      'liga' off;
    font-family: fontInter.$font-family-inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    &--detail {
      min-height: 200px;
    }
  }
  &__arrow {
    content: '';
    margin-left: 30px;
    line-height: 40px;
    margin-bottom: 5px;
    background-image: url('src/assets/images/icons/arrow-right.svg');
    background-repeat: no-repeat;
    width: 18px;
    background-position: center;
    background-size: 100%;
    height: 18px;
    position: absolute;
    bottom: 20px;
    right: 40px;
  }
  &:hover {
    text-decoration: none;
    .blog-item__arrow {
      animation: bounceRight 1s infinite;
    }
    .blog-item__text {
      text-decoration: none;
    }
    .blog-item___title {
      text-decoration: none;
    }
  }
}

.blog-top-image {
  width: 100%;
  height: auto;
  border-radius: 20px;
  max-height: 500px;
  object-fit: cover;
  margin-bottom: 20px;
}

@keyframes bounceRight {
  0%,
  20%,
  50%,
  80%,
  100% {
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
  40% {
    -ms-transform: translateX(-20px);
    transform: translateX(-20px);
  }
  60% {
    -ms-transform: translateX(-10px);
    transform: translateX(-10px);
  }
}
