@use '../base/fonts/inter.scss' as fontInter;
@use '../base/mixins';
.software {
    display: flex;
    justify-content: space-between;
    padding: 100px;
    @include mixins.respond-to(md) {
        padding: 0;
        padding-bottom: 30px;
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
        scrollbar-width: none;
        ms-overflow-style: none;
        &::-webkit-scrollbar {
            display: none;
        }
      }
    &__item {
        width: 220px;
        @include mixins.respond-to(md) {
            flex: 0 0 auto;
            margin-right: 50px;
        }
    }
    &__icon {
        width: 46px;
        height: 46px;
        background-color: #fff;
        margin-bottom: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        img {
            width: 24px;
            height: auto;
        }
    }
    &__title {
        color: #070058;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: fontInter.$font-family-inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 25px;
        display: inline-flex;
        align-items: center;
        position: relative;
        margin-bottom: 26px;
        cursor: pointer;
        @include mixins.animation();
        &:after{
            content: '';
            position: absolute;
            line-height: 40px;
            background-image: url('src/assets/images/icons/arrow-right.svg');
            background-repeat: no-repeat;
            width: 18px;
            background-position: center;
            background-size: 100%;
            height: 18px;
            right: -40px;
        }
        &::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 1px;
            background-color: #070058;
            bottom: 0;
            left: 0;
            transform-origin: right;
            transform: scaleX(0);
            transition: transform .3s ease-in-out;
        }
        &:hover {
            &::after {
            animation: bounceRight 1s infinite;
            }
            &::before {
                transform-origin: left;
                transform: scaleX(1);
            }
        }
    }
    &__text {
        color: rgba(7, 0, 88, 0.80);
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Epilogue;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px;
    }
    &--reference {
        @include mixins.respond-to(md) {
            padding: 40px 10px;
        }
        .software__icon {
            width: auto;
            height: 50px;
            justify-content: flex-start;
            background-color: transparent;
            img {
                height: auto;
                width: auto;
            }
        }
        .software__title {
            cursor: default;
            &:hover {
                &::before {
                    content: none;
                }
            }
            &::after {
                content: none;
            }
        }
    }
    &--products {
        padding-bottom: 0;
        justify-content: flex-start;
        gap: 150px;
        @include mixins.respond-to(xl) {
            gap: 100px;
        }
        @include mixins.respond-to(lg) {
            gap: 50px;
        }
        @include mixins.respond-to(md) {
            padding: 40px 10px;
            gap: 20px;
        }
        .software__icon {
            width: 46px;
            height: 46px;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            background-color: #fff;
            img {
                height: auto;
                width: auto;
            }
        }
        .software__title {
            cursor: default;
            &:hover {
                &::before {
                    content: none;
                }
            }
            &::after {
                content: none;
            }
        }
    }

    &--marketing {
        justify-content: flex-start;
        gap: 150px;
        background-color: rgba(7, 0, 88, 0.05);
        border-radius: 15px;
        padding: 50px 70px;
        margin: 50px 80px 80px 20px;
        @include mixins.respond-to(xl) {
            gap: 100px;
            margin-right: 50px;
        }
        @include mixins.respond-to(lg) {
            padding: 40px;
            gap: 50px;
            margin-right: 20px;
            margin-left: 0;
        }
        @include mixins.respond-to(md) {
            padding: 40px 20px;
            gap: 20px;
            margin-right: 0;
            margin-left: 0;
        }
        .software__icon {
            width: 46px;
            height: 46px;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            background-color: #fff;
            img {
                height: auto;
                width: auto;
            }
        }
        .software__title {
            cursor: default;
            &:hover {
                &::after {
                    content: none;
                    animation: none;
                }
                &::before {
                    content: none;
                }
            }
            &:hover {
                &::before {
                    content: none;
                }
            }
            &::after {
                content: none;
            }
        }
        .software__text {
            color: rgba(7, 0, 88, 0.80);
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: Epilogue;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 28px;
        }
    }
}