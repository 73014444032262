.category__wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
    padding: 0 15px;
    width: 100%;
    ul {
        display: inline;
        padding-left: 0;
        border-radius: 17px;
        padding: 4px 10px;
        width: 100%;
        background: rgba(8, 0, 92, 0.05);
    }
}

.category__item {
    text-transform: uppercase;
    border-radius: 8px;
    background:#4BC6B6;
    color: #FFF;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 1px;
    padding: 0 30px;
    height: 24px;
    margin: 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}