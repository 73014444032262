@use '../base/fonts/inter.scss' as fontInter;
@use '../base/mixins';
.intro {
  display: flex;
  align-items: flex-start;
  position: relative;
  height: 50vh;
  min-height: 600px;
  padding-top: 150px;
  @include mixins.respond-to(md) {
    height: auto;
    padding-top: 100px;
  }
  @include mixins.respond-to(sm) {
    height: auto;
    text-align: center;
    padding-top: 100px;
  }
  &--products {
    justify-content: center;
    text-align: center;
    padding-top: 50px;
    padding-bottom: 100px;
    min-height: auto;
    height: auto;
    @include mixins.respond-to(md) {
      height: auto;
      padding-bottom: 100px;
    }
    @include mixins.respond-to(sm) {
      height: auto;
      padding-bottom: 60px;
    }
  }
  &--product-detail {
    justify-content: center;
    text-align: center;
    padding-top: 50px;
    padding-bottom: 0;
    min-height: auto;
    height: auto;
    .intro__text {
      margin: 0 auto 30px;
      padding: 0 20px;
    }
    @include mixins.respond-to(md) {
      height: auto;
      padding-bottom: 50px;
    }
  }
  &--detail {
    justify-content: center;
    text-align: center;
    padding-top: 50px;
    padding-bottom: 80px;
    min-height: auto;
    height: auto;
    @include mixins.respond-to(md) {
      height: auto;
    }
  }
  &__title {
    color: #070058;
    font-feature-settings:
      'clig' off,
      'liga' off;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 102.083%;
    margin-bottom: 16px;
    @include mixins.respond-to(md) {
      font-size: 28px;
      line-height: 119%;
    }
  }
  &__text {
    color: #070058;
    font-feature-settings:
      'clig' off,
      'liga' off;
    font-family: fontInter.$font-family-inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 25px;
    margin-bottom: 30px;
    opacity: 0.5;
    @include mixins.respond-to(md) {
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 20px;
    }
  }
  &__mac {
    text-align: right;
    @include mixins.respond-to(md) {
      text-align: center;
    }
    img {
      width: 100%;
      max-width: 450px;
      height: auto;
    }
  }
}
