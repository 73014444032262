@use '../base/mixins';
$font-family: 'Epilogue', sans-serif !default;
$font-size: 16px !default;
$font-family-inter: 'Inter', sans-serif !default;


body {
  font-family: $font-family;
  font-size: $font-size;
}

// Headings

$_head-font-weight: 600;
$_gap-top: 24px;
$_gap-bottom: 16px;

h1, h2, h3, h4, h5, h6 {
/*   -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto; */

  // Prevent code inside headings from being capitalized.
  code {
    text-transform: initial;
  }
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6,
.s1, .s2, .s3, .s4, .s5, .s6 {

}

// Headlines

h1, .h1 {
  font-weight: $_head-font-weight;
  font-size: 32px;
  line-height: 40px;
}

h2, .h2 {
  font-weight: $_head-font-weight;
  font-size: 24px;
  line-height: 30px;
}

h3, .h3 {
  font-weight: $_head-font-weight;
  font-size: 20px;
  line-height: 25px;
}

h4, .h4 {
  font-weight: $_head-font-weight;
  font-size: 16px;
  line-height: 20px;
}

h5, .h5 {
  font-weight: $_head-font-weight;
  font-size: 14px;
  line-height: 18px;
}

h6, .h6 {
  font-weight: $_head-font-weight;
  font-size: 13.7px;
  line-height: 18px;
  color: #6a737d;
}

// Sublines
$_subline-font-weight: normal;
.s1 {
  font-weight: $_subline-font-weight;
  font-size: 32px;
  line-height: 40px;
}

.s2 {
  font-weight: $_subline-font-weight;
  font-size: 24px;
  line-height: 30px;
}

.s3 {
  font-weight: $_subline-font-weight;
  font-size: 20px;
  line-height: 25px;
}

.s4 {
  font-weight: $_subline-font-weight;
  font-size: 16px;
  line-height: 20px;
}

.s5 {
  font-weight: $_subline-font-weight;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.025em;
}

.s6 {
  font-weight: $_subline-font-weight;
  color: #6a737d;
  font-size: 13.7px;
  line-height: 18px;
  letter-spacing: 0.025em;
}

// Text

b, .b,
strong,
.strong {
  font-weight: bold;
}

.i {
  font-style: italic;
}

// Paragraph

// large
.p-lg {
  margin: 10px 0;
  font-size: 20px;
  line-height: 24px;
}

// medium
.p-md {
  margin: 10px 0;
  font-size: 18px;
  line-height: 22px;
}

// default
p, .p {
  margin: 10px 0;
  font-size: 16px;
  line-height: 20px;
}

// small
.p-sm {
  margin: 10px 0;
  font-size: 14px;
  line-height: 18px;
}

// extra small
.p-xs {
  margin: 10px 0;
  font-size: 12px;
  line-height: 16px;
}

a {
  @include mixins.animation();
}
.section-title {
  display: flex;
  justify-content: space-between;
  border-bottom: 1.5px solid rgba(8, 0, 92, 0.1);
  padding: 20px 0;
  &__title {
    color: #070058;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0;
    @include mixins.respond-to(md) {
      font-size: 22px;
    }
  }
  &__left {
    display: flex;
    align-items: center;
  }
  &__right {
    display: flex;
    align-items: center;
    @include mixins.respond-to(md) {
      display: none;
    }
    a {
      color: #070058;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      display: flex;
      align-items: center;
      position: relative;
      margin-right: 40px;
      @include mixins.animation();
      &::after{
          content: '';
          margin-left: 30px;
          line-height: 40px;
          margin-bottom: 5px;
          background-image: url('src/assets/images/icons/arrow-right.svg');
          background-repeat: no-repeat;
          width: 18px;
          background-position: center;
          background-size: 100%;
          height: 18px;
          position: absolute;
          right: -40px;
      }
      &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: #070058;
        bottom: 0;
        left: 0;
        transform-origin: right;
        transform: scaleX(0);
        transition: transform .3s ease-in-out;
    }
      &:hover {
        &::after {
          animation: bounceRight 1s infinite;
        }
        &::before {
          transform-origin: left;
          transform: scaleX(1);
      }
      }
    }
  }
  &__icon {
    height: 46px;
    width: 46px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 24px;
    @include mixins.respond-to(md) {
      display: none;
    }
    &--pohoda {
        background: linear-gradient(180deg, #1273EF 0%, #0D5DC5 100%);
    }
    &--sklad {
        background: linear-gradient(180deg, #2E09AE 0%, #08005C 100%);
    }
    &--vyvoj {
        background: linear-gradient(180deg, #4BC6B6 0%, #37A395 100%);
    }
    &--marketing {
        background: linear-gradient(180deg, #F75543 0%, #AE2B1C 100%);
    }
    &--integrace {
        background: linear-gradient(180deg, #FAAD21 0%, #D4931D 100%);
    }
    img {
      width: 23px;
      height: auto;
    }
  }
}
