@use '../base/mixins';
.navigation {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  &.active {
    display: flex;
  }
  .hidden-pc-socials {
    display: none;
    order: 3;
    margin-left: auto;
    margin-right: 20px;
    margin-top: 20px;
    .header-social {
      margin: 0 8px;
      svg {
        height: 16px;
        width: auto;
        margin-bottom: 5px;
        path {
          fill-opacity: 1;
        }
      }
    }
    @include mixins.respond-to(md) {
      display: flex;
    }
  }
}
.menu-button-container {
  display: none;
  height: 100%;
  width: 30px;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#menu-toggle {
  display: none;
}

.menu-button,
.menu-button::before,
.menu-button::after {
  display: block;
  background-color: #070058;
  position: absolute;
  height: 2px;
  width: 30px;
  transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
  border-radius: 2px;
}

.menu-button::before {
  content: '';
  margin-top: -8px;
}

.menu-button::after {
  content: '';
  margin-top: 8px;
}

#menu-toggle:checked + .menu-button-container .menu-button::before {
  margin-top: 0px;
  transform: rotate(405deg);
}

#menu-toggle:checked + .menu-button-container .menu-button {
  background: rgba(255, 255, 255, 0);
}

#menu-toggle:checked + .menu-button-container .menu-button::after {
  margin-top: 0px;
  transform: rotate(-405deg);
}

@include mixins.respond-to(md) {
  .menu-button-container {
    display: flex;
  }
  .navigation {
    position: absolute;
    top: 0;
    margin-top: 40px;
    left: 0;
    flex-direction: column;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    background: rgba(255, 255, 255, 0.82);
    backdrop-filter: blur(7px);
    height: 100vh;
    display: none;
  }
  #menu-toggle ~ .menu li {
    height: 0;
    margin: 0;
    padding: 0;
    border: 0;
    transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
  }
  #menu-toggle:checked ~ .menu li {
    border: 1px solid #333;
    height: 2.5em;
    padding: 0.5em;
    transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
  }
  .menu > li {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0.5em 0;
    width: 100%;
    color: white;
    background-color: #222;
  }
  .menu > li:not(:last-child) {
    border-bottom: 1px solid #444;
  }
}
