@use '../base/mixins';
.contact {
  padding-top: 50px;
}

.contact-box {
  padding: 70px 100px;
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.04);
  margin-bottom: 90px;
  position: relative;
  @include mixins.respond-to(md) {
    padding: 40px 20px 60px 20px;
    margin-bottom: 50px;
  }
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 10%;
    background-image: url('src/assets/images/icons/contact-detail-bubble.png');
    z-index: 1;
    background-repeat: no-repeat;
    width: 350px;
    background-position: bottom;
    background-size: 100%;
    height: 100%;
    @include mixins.respond-to(md) {
      width: 50%;
      right: 5%;
    }
  }
  &__right {
    z-index: 2;
    position: relative;
  }
  h1 {
    color: #070058;
    text-align: center;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 56px;
    margin-bottom: 60px;
    @include mixins.respond-to(md) {
      font-size: 32px;
      line-height: 40px;
      margin-bottom: 30px;
    }
  }
  h3 {
    color: #070058;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    margin-bottom: 15px;
  }
  p {
    color: rgba(7, 0, 88, 0.8);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    max-width: 300px;
    @include mixins.respond-to(md) {
      font-size: 16px;
      line-height: 26px;
    }
  }
  form {
    display: flex;
    flex-direction: column;
    &.hidden {
      display: none;
    }
  }
  &__call {
    border-radius: 12px;
    background-color: rgba(177, 227, 253, 0.25);
    margin: 30px 0;
    color: #08005c;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 30px;
    padding: 12px 20px;
    @include mixins.respond-to(md) {
      margin: 20px 0;
    }
    strong {
      font-weight: 600;
      font-size: 18px;
      margin-right: 25px;
      @include mixins.respond-to(md) {
        margin-right: 20px;
        display: block;
      }
    }
  }
  input {
    border-radius: 12px;
    background-color: rgba(177, 227, 253, 0.25);
    margin: 15px 0;
    color: #08005c;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    padding: 12px 20px;
    border: none;
    max-width: 400px;
    &::placeholder {
      color: #08005c;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }
  }
  textarea {
    border-radius: 12px;
    background-color: rgba(177, 227, 253, 0.25);
    margin: 15px 0;
    color: #08005c;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    padding: 12px 20px;
    border: none;
    margin-bottom: 30px;
    min-height: 130px;
    &::placeholder {
      color: #08005c;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }
  }
  button {
    max-width: 200px;
    color: #070058;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    border-radius: 34px;
    border: 1px solid #08005c;
    @include mixins.respond-to(md) {
      margin-bottom: 30px;
    }
  }
}

.contact-calendar {
  padding: 70px 100px;
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.04);
  margin-bottom: 90px;
  position: relative;
  @include mixins.respond-to(md) {
    padding: 40px 20px 60px 20px;
    margin-bottom: 50px;
  }
  &__inner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
  }
  &__button {
    margin: 20px 0;
  }
  &__image {
    width: 100%;
    height: auto;
  }
}

.contact-mid {
  padding-bottom: 100px;
  @include mixins.respond-to(md) {
    padding-bottom: 50px;
  }
  &__item {
    display: flex;
    flex-direction: column;
    @include mixins.respond-to(sm) {
      flex-direction: row;
      margin-bottom: 30px;
      align-items: center;
    }
  }
  h3 {
    color: #070058;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    margin-bottom: 50px;
  }
  p {
    color: #08005c;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
  }
  iframe {
    margin-top: 20px;
    width: 100%;
    height: 400px;
    border-radius: 20px;
  }
  &__socials {
    @include mixins.respond-to(sm) {
      margin-bottom: 50px;
    }
  }
  &__icon {
    height: 46px;
    width: 46px;
    background-color: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    @include mixins.respond-to(sm) {
      margin-bottom: 0;
    }
  }
  &__name {
    margin-bottom: 30px;
    @include mixins.respond-to(sm) {
      margin-left: 20px;
      margin-bottom: 0;
    }
    a {
      color: #08005c;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }
  }
}

.contact-bottom {
  padding-bottom: 100px;
  h3 {
    color: #070058;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    margin-bottom: 50px;
  }
  p {
    color: #08005c;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    strong {
      font-weight: 700;
    }
  }
  a {
    color: #08005c;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      background-color: #070058;
      bottom: 0;
      left: 0;
      transform-origin: right;
      transform: scaleX(0);
      transition: transform 0.3s ease-in-out;
    }
    &:hover {
      text-decoration: none;
      opacity: 1;
      &::after {
        transform-origin: left;
        transform: scaleX(1);
      }
    }
  }
}

.success-form {
  display: none;
  min-height: 400px;
  &__title {
    font-size: 24px;
    line-height: 40px;
    font-weight: 400;
    color: #070058;
    position: relative;
    padding-left: 30px;
    &::before {
      content: '';
      position: absolute;
      width: 18px;
      height: 10px;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      background-image: url('src/assets/images/icons/success-form.svg');
    }
  }
  &__text {
    p {
      margin-bottom: 20px;
      font-size: 18px;
      line-height: 30px;
      font-weight: 400;
      a {
        color: #070058;
        font-weight: 700;
      }
    }
  }
  &.active {
    display: block;
  }
}

.form__wrapper--thankyou {
  display: none;
}

.form__wrapper--error {
  display: none;
}

.contact-calendar {
  text-align: center;

  h3 {
    color: #070058;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    margin-bottom: 15px;
  }

  .calendly-inline-widget {
    @include mixins.respond-to(sm) {
      height: 950px !important;
    }
  }
}
