@use '../base/fonts/inter.scss' as fontInter;
@use '../base/mixins';
.button-primary {
  background-color: #1273ef;
  border-radius: 34px;
  color: #fff;
  display: inline-flex;
  justify-content: center;
  font-family: fontInter.$font-family-inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  padding: 20px 30px;
  transition: all 0.3s ease-in-out;
  &:hover {
    background: #0d62cf;
    box-shadow: 1px 1px 5px 0px rgba(18, 115, 239, 0.45);
  }
  &:active {
    background: #0d62cf;
    box-shadow: 1px 1px 5px 0px rgba(18, 115, 239, 0.45);
  }
  @include mixins.respond-to(md) {
    font-size: 12px;
  }

  &--small {
    padding: 6px 14px;
    font-size: 12px;
  }
}

.button-secondary {
  background-color: #fff;
  border-radius: 34px;
  color: #fff;
  display: inline-flex;
  justify-content: center;
  color: #070058;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  padding: 20px 30px;
  transition: all 0.3s ease-in-out;
  &:hover {
    background: #e8e8e8;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.04);
  }
  &:active {
    background: #e8e8e8;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.04);
  }
  @include mixins.respond-to(md) {
    font-size: 12px;
  }
}
