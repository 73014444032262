@use '../base/mixins';
.breadcrumbs {
    list-style: none;
    padding: 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding-top: 150px;
    @include mixins.respond-to(md) {
        padding-top: 80px;
      }
    li {
        margin-right: 10px;
        &::after {
            content: '';
            margin-left: 10px;
            line-height: 24px;
            background-image: url('src/assets/images/icons/arrow-right-breadcrumbs.svg');
            background-repeat: no-repeat;
            width: 6px;
            background-position: center;
            background-size: 100%;
            height: 9px;
            display: inline-block;
        }
        &:first-child {
            a {
                &::after {
                    content: none;
                } 
            }
        }
        &:last-child {
            &::after {
                content: none;
            }
            a {
                color: #070058;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                margin: 0 10px;
                opacity: 1;
                cursor: default;
                &::after {
                    content: none;
                }
            }
        }
        a {
            text-decoration: none;
            color: #070058;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            position: relative;
            margin: 0 10px;
            opacity: 0.5;
            &::after {
                content: '';
                position: absolute;
                width: 100%;
                height: 1px;
                background-color: #070058;
                bottom: 0;
                left: 0;
                transform-origin: right;
                transform: scaleX(0);
                transition: transform .3s ease-in-out;
            }
            &:hover {
                text-decoration: none;
                opacity: 1;
                &::after {
                    transform-origin: left;
                    transform: scaleX(1);
                }
            }
        }
        img {
            line-height: 24px;
            margin-right: 10px;
            margin-bottom: 2px;
        }
    }
}