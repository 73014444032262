.partners {
    &__item {
        border-top: 1px solid rgba(8, 0, 92, 0.1);
        padding: 30px 0 50px 0;
    }
    &__logo {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        border-radius: 10px;
        width: 180px;
        height: 80px;
        margin-bottom: 30px;
        img {
           max-height: 60px;
           height: auto;
           width: auto;
           max-width: 100px; 
        }
    }
    &__title {
        padding-left: 10px;
        margin-bottom: 20px;
        color: #070058;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
    &__text {
        color: rgba(7, 0, 88, 0.80);
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px; 
        margin-bottom: 20px;
    }
    &__link {
        color: #070058;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        position: relative;
        &::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 1px;
            background-color: #070058;
            bottom: 0;
            left: 0;
            transform-origin: right;
            transform: scaleX(0);
            transition: transform .3s ease-in-out;
        }
        &:after{
            content: '';
            position: absolute;
            line-height: 40px;
            background-image: url('src/assets/images/icons/arrow-right.svg');
            background-repeat: no-repeat;
            width: 18px;
            background-position: center;
            background-size: 100%;
            height: 18px;
            right: -40px;
        }
        &:hover {
            &::after {
            animation: bounceRight 1s infinite;
            }
            &::before {
                transform-origin: left;
                transform: scaleX(1);
            }
        }
    }
}