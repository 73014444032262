@use '../base/fonts/inter.scss' as fontInter;
@use '../base/mixins';
.contact-banner {
    margin: 150px 0;
    border-radius: 20px;
    background: #FFF;
    position: relative;
    @include mixins.respond-to(md) {
        margin: 100px 0;
      }
    &__bubble {
        content: '';
        position: absolute;
        bottom: 0;
        left: 5%;
        background-image: url('src/assets/images/icons/contact_bubble.png');
        z-index: 1;
        background-repeat: no-repeat;
        width: 250px;
        background-position: bottom;
        background-size: 100%;
        height: 100%;
        @include mixins.respond-to(md) {
            width: 150px;
            left: 3%;
          }
    }
    &__inner {
        z-index: 1;
        overflow: hidden;
        position: relative;
        padding: 60px 20px 50px 20px;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        @include mixins.respond-to(md) {
            padding: 60px 20px 100px 20px;
          }
        &::after {
            content: '';
            position: absolute;
            inset: 0;
            background-image: url('src/assets/images/icons/circle.svg');
            z-index: 0;
            background-repeat: no-repeat;
            text-align: center;
            width: auto;
            height: auto;
            background-position: center;
            background-size: 800px; 
            animation: expandFade3 6s linear infinite;
            pointer-events: none;
        }
        &::before {
            content: '';
            position: absolute;
            inset: 0;
            background-image: url('src/assets/images/icons/circle.svg');
            z-index: 0;
            background-repeat: no-repeat;
            text-align: center;
            width: auto;
            height: auto;
            background-position: center;
            background-size: 1100px; 
            animation: expandFade4 6s linear infinite;
            pointer-events: none;
        }
    }
    &::after {
        content: '';
        position: absolute;
        inset: 0;
        background-image: url('src/assets/images/icons/circle.svg');
        z-index: 0;
        background-repeat: no-repeat;
        text-align: center;
        width: auto;
        height: auto;
        background-position: center;
        background-size: 300px; 
        animation: expandFade1 6s linear infinite;
        pointer-events: none;
    }
    &::before {
        content: '';
        position: absolute;
        inset: 0;
        background-image: url('src/assets/images/icons/circle.svg');
        z-index: 0;
        background-repeat: no-repeat;
        text-align: center;
        width: auto;
        height: auto;
        background-position: center;
        background-size: 500px; 
        animation: expandFade2 6s linear infinite;
        pointer-events: none;
    }
    h2 {
        color: #070058;
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;
        font-size: 48px;
        font-style: normal;
        font-weight: 600;
        line-height: 102.083%;
        @include mixins.respond-to(md) {
            font-size: 28px;
          }
    }
    p {
        color: #070058;
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        font-family: fontInter.$font-family-inter;
        margin-bottom: 20px;
    }
}

@keyframes expandFade1 {
    0% {
        opacity: 1;
        background-size: 300px;
    }
    50% {
        opacity: 0.7;
        background-size: 500px;
    }
    100% {
        opacity: 0;
        background-size: 700px;
    }
}
@keyframes expandFade2 {
    0% {
        opacity: 1;
        background-size: 600px;
    }
    50% {
        opacity: 0.7;
        background-size: 800px;
    }
    100% {
        opacity: 0;
        background-size: 1000px;
    }
}
@keyframes expandFade3 {
    0% {
        opacity: 1;
        background-size: 900px;
    }
    50% {
        opacity: 0.7;
        background-size: 1100px;
    }
    100% {
        opacity: 0;
        background-size: 1300px;
    }
}
@keyframes expandFade4 {
    0% {
        opacity: 1;
        background-size: 1200px;
    }
    50% {
        opacity: 0.7;
        background-size: 1400px;
    }
    100% {
        opacity: 0;
        background-size: 1600px;
    }
}