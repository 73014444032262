@use '../base/fonts/inter.scss' as fontInter;
@use '../base/mixins';
.stats {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    margin: 200px 0 80px;
    @include mixins.respond-to(sm) {
        flex-direction: column;
        align-items: center;
        margin: 50px 0;
      }
    &__item {
        border-radius: 85px;
        background: #FFF;
        margin: 0 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 130px;
        width: 200px;
        text-align: center;
        padding: 30px 20px;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.04);
        @include mixins.respond-to(sm) {
            margin: 10px 0;
          }
        &:first-child {
            transform: translateY(50px);
            @include mixins.respond-to(sm) {
                transform: translateY(0);
              }
        }
        &:nth-child(2){
            transform: translateY(-150px);
            width: 280px;
            height: 160px;
            @include mixins.respond-to(sm) {
                transform: translateY(0);
              }
            .stats__number {
                margin-bottom: 20px;
            }
        }
        &:last-child {
            transform: translateY(20px);
            @include mixins.respond-to(sm) {
                transform: translateY(0);
              }
        }
        &:last-child {
            .stats__number {
                &:before {
                    content: '';
                }
            }
        }
    }
    &__number {
        color: #1273EF;
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 5px;
        position: relative;
        &:before {
            content: '+';
            position: absolute;
            left: -20px;
        }
        &.no-plus {
            &:before {
                content: '';
            }
        }
    }
    &__text {
        color: #070058;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: fontInter.$font-family-inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        text-wrap: pretty;
    }
}