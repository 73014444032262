@use '../base/fonts/inter.scss' as fontInter;
@use '../base/mixins';
.services {
  margin: 50px 0;
  @include mixins.respond-to(md) {
    margin: 50px 0 20px 0;
  }
  &__inner {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    padding: 26px 0;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  &__item {
    border-radius: 20px;
    border: 1px solid rgba(8, 0, 92, 0.04);
    background: rgba(244, 244, 247, 0.58);
    padding: 25px 20px;
    position: relative;
    height: 300px;
    width: 225px;
    display: block;
    transition: transform 0.3s ease;
    margin-right: 26px;
    flex: 0 0 auto;
    @include mixins.respond-to(lg) {
      width: 200px;
      min-height: 300px;
      padding: 20px 15px;
    }
    &:hover {
      text-decoration: none;
      transform: translateY(-20px);
      .services__arrow {
        animation: bounceRight 1s infinite;
      }
      .services__text {
        text-decoration: none;
      }
      .services___title {
        text-decoration: none;
      }
    }
  }
  &__icon {
    height: 46px;
    width: 46px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    &--pohoda {
      background: linear-gradient(180deg, #1273ef 0%, #0d5dc5 100%);
    }
    &--sklad {
      background: linear-gradient(180deg, #2e09ae 0%, #08005c 100%);
    }
    &--vyvoj {
      background: linear-gradient(180deg, #4bc6b6 0%, #37a395 100%);
    }
    &--marketing {
      background: linear-gradient(180deg, #f75544 0%, #ad2a1c 100%);
    }
    &--integrace {
      background: linear-gradient(180deg, #faad21 0%, #d4931d 100%);
    }
  }
  &__icons {
    display: flex;
    justify-content: space-between;
    margin-bottom: 70px;
    @include mixins.respond-to(md) {
      margin-bottom: 40px;
    }
    img {
      width: 23px;
      height: auto;
    }
  }
  &__arrow {
    content: '';
    margin-left: 30px;
    line-height: 40px;
    margin-bottom: 5px;
    background-image: url('src/assets/images/icons/arrow-right.svg');
    background-repeat: no-repeat;
    width: 18px;
    background-position: center;
    background-size: 100%;
    height: 18px;
    position: absolute;
    right: 24px;
    top: 36px;
  }
  &__title {
    color: #070058;
    font-feature-settings:
      'clig' off,
      'liga' off;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
  }
  &__text {
    color: rgba(7, 0, 88, 0.8);
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    font-family: fontInter.$font-family-inter;
    text-wrap: pretty;
    strong {
      opacity: 0.8;
      font-weight: 700;
    }
  }
  h4 {
    padding-left: 30px;
    opacity: 0.7;
    color: #070058;
    font-family: fontInter.$font-family-inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  &__left {
    padding-right: 26px;
    @include mixins.respond-to(md) {
      padding-right: 0;
    }
  }
  &__right {
    @include mixins.respond-to(md) {
      padding-right: 0;
    }
  }
}
