// Max width of all header, banners, widgets, etc. It can be wider then page section content.
$page-max-width: 1280px !default;
// Max width of content in page section.
$content-max-width: 960px !default;

// Notation of spaces for global usage (padding, margin):
// $gap-<size> is inner space (e.g. padding), defaults is $gap
$gap-xl: 30px !default;
$gap-lg: 20px !default;
$gap: 10px !default;
$gap-md: 5px !default;
$gap-sm: 2px !default;
$gap-xs: 1px !default;

// Notation of gutter by row and column:
// $<row|column>-gap-<size> is outer space (e.g. margin), defaults is <row|column>-gap
//
// Space between items in row container.
$row-gap-xl: 30px !default;
$row-gap-lg: 20px !default;
$row-gap: 10px !default;
$row-gap-md: 5px !default;
$row-gap-sm: 2px !default;
$row-gap-xs: 1px !default;
// Space between items in column container.
$column-gap-xl: 30px !default;
$column-gap-lg: 20px !default;
$column-gap: 10px !default;
$column-gap-md: 5px !default;
$column-gap-sm: 2px !default;
$column-gap-xs: 1px !default;
